import React, {useState} from "react"
import Aufklapper from "./Aufklapper";
import {BROWSER, DESKTOP, TABLET, PHONE, WINDOWS, APPLE, ANDROID, VIEWS} from "../components/SVGs";


interface Props {
    i: any,
    idx: number
}

const Device:React.FC<Props> = (Props):JSX.Element => {
    const [open, setopen] = useState(false);

    return (
        <div className="DeviceSpalte">
            <div className="DeviceHeader" onClick={() => setopen(!open)}>
                <p style={{margin: 0}}>Gerät {Props.idx+1}</p>
                <Aufklapper aufgeklappt={open} height={22} widht={22} right={true}></Aufklapper>
            </div>
            {open && 
                <div className="DeviceInfoBody">
                    <div className="DeviceInfoPart">
                        {BROWSER}
                        <p>{Props.i.Browser}</p>
                    </div>
                    <div className="DeviceInfoPart">
                        {(Props.i.Type === "desktop") ? DESKTOP : ((Props.i.Type === "mobile") ? PHONE : TABLET)}
                        <p>{Props.i.Type}</p>
                    </div>
                    <div className="DeviceInfoPart">
                        {(Props.i.System === "Android") ? ANDROID : ((Props.i.System === "Windows") ? WINDOWS : APPLE)}
                        <p>{Props.i.System}</p>
                    </div>
                    <div className="DeviceInfoPart">
                        {VIEWS}
                        <p>{Props.i.Aufrufe+1}</p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Device;
