import React from "react";
import "../css/EigeneSeite.css";
import backarrowIcon from "../pictures/arrow-back-icon.jpg"

interface BilderVorschrift {
    path: string, 
    alt: string, 
    ÜberschriftBild?:string, 
    UnterschriftBild?:string
}

interface FragenVorschrift {
    Frage: string, Antwort: string
}

interface Props {
    Bilder: Array<BilderVorschrift>, 
    Überschrift: string, 
    Fragen: Array<FragenVorschrift>,
    SourceCodeLink: string, 
    Download?: string,
    Demo?: string,
}

const EigeneSeite:React.FC<Props> = (Props):JSX.Element => {
    return(
        <div className="EigeneSeiteElement EigeneSeiteVolleSeite">
            <div className="EigeneSeiteElement EigeneSeiteInnerDIV">
                <div className="EigeneSeiteElement EigeneSeiteInnerInnerDIV">
                    <div className="EigeneSeiteElement EigeneSeiteTopLine">
                        <div className="EigeneSeiteElement EigeneSeiteTopLineLeftFlexDIV">
                            <a className="EigeneSeiteElement EigeneSeiteGoBack" href="/Projekte"><img alt="BACK" src={backarrowIcon}></img></a>
                        </div>
                        <div className="EigeneSeiteElement EigeneSeiteÜberschrift">
                            <h1>{Props.Überschrift}</h1>
                        </div>
                            <div className="EigeneSeiteElement EigeneSeiteTopRightEmptyDIV">
                        </div>
                    </div>
                    <div className="EigeneSeiteElement EigeneSeiteDownDIV">
                        <div className="EigeneSeiteElement EigeneSeiteBaukasten">
                            {Props.Bilder.map(i => <div className={"EigeneSeiteElement EinzelnerBilderKasten"}>
                                    {i.ÜberschriftBild && <h1 className="EigeneSeiteElement EigeneSeiteBilderkastenÜberschrift ">{i.ÜberschriftBild}</h1>}
                                    <div className="EigeneSeiteElement EigeneSeiteKastenUnterDerÜberschrift">
                                        <img src={i.path} alt={i.alt}></img>
                                        {i.UnterschriftBild && <h3>{i.UnterschriftBild}</h3>}
                                    </div>
                                </div>)}
                        </div>
                        <div className="EigeneSeiteElement EigeneSeiteLangeBeschreibung">
                            {Props.Fragen.map(i => { 
                                return (
                                    <div>
                                        <h1 style={{marginBottom: "0"}}>{i.Frage}</h1>
                                        <hr style={{width: "100%"}}></hr>
                                        <p>{i.Antwort}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div  className="EigeneSeiteElement EigeneSeiteDownloadButtons">
                            <a href={Props.SourceCodeLink} target="_blank" rel="noreferrer">Sourcecode</a>
                            {Props.Download && <a href={Props.Download} target="_blank" rel="noreferrer">Download</a>}
                            {Props.Demo && <a href={Props.Demo} target="_blank" rel="noreferrer">View Demo</a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EigeneSeite; export type {BilderVorschrift, FragenVorschrift};

