import React, {useState} from "react";
import SpammerLogo from "../pictures/SpammerFront.png";
import BukkitLogo from "../pictures/minecraft.png";
import MinecraftLogo from "../pictures/minecraftdiscord.png";
import ComingSoonLogo from "../pictures/Coming-Soon.png";
import YouTubeDownloadLogo from "../pictures/youtubedownload.png";
import WunschlistenLogo  from "../pictures/WunschlistenWebsiteBild.png";

type Daten = {
    BildSource: string, 
    Header: string, 
    Beschreibung: string, 
    buttonfn?: () => void, 
    Csharp?: boolean, 
    Python?: boolean, 
    Java?: boolean, 
    JavaScript?: boolean,
    Typescript?: boolean,
    HTML?: boolean,
    CSS?: boolean,
    btn?: boolean
}

const ProjekteAuflistung:React.FC = ():JSX.Element => {
    const handleButtonClick = ():void => {
        window.location.replace("http://finnkrause.com/?Sprachentable=true");
    } 

    const [Projekte] = useState<Array<Daten>>([
        {btn: true, Csharp: true, BildSource: SpammerLogo, buttonfn: () => {handleButtonClick()},Header: "Spammer", Beschreibung: "Der Spammer ist eins meiner Projekte, die ich mit C# geschrieben habe! Der Spammer ist eine vollwertige Windows-Desktopanwendung mit vielen Einstellungen, um einen vom Nutzer eingegeben Text, eine gewisse Anzahl an Wiederholungen und in einem eingegebenen Abstand als Tastatureingabe auszuführen. Dabei kann man sich ein eigenes Hintergrundbild hochladen und zwischen verschiedenen Modi hin und herswitchen. Bei jedem Schließen speichert das Programm dann eine Konfigurationsdatei, die die eingestellten Werte beinhaltet, um das Programm beim nächsten Start entspannter bedienen zu können."},
        {btn: true, Java:true,JavaScript:true, Python:true, BildSource: BukkitLogo,buttonfn: () => {handleButtonClick()}, Header: "Minecraft-Plugin", Beschreibung: "Das Minecraft-Plugin habe ich speziell für meinen Server in Java entwickelt und auf die Wünsche von meinen Freunden und mir angepasst. Es gibt Spielern zum Beispiel die Möglichkeit, sich gewisse Spielabschnitte vor Zerstörung von anderen zu sichern, sich Items gegen bestimmte Bezahlungen zu verbessern, die Position anderer Spieler abzufragen und vieles mehr ... "},
        {btn: true, Python:true, JavaScript:true, BildSource: MinecraftLogo, buttonfn: () => {handleButtonClick()}, Header: "Discord-Bots", Beschreibung: "Ein sehr gutes Beispiel für die Verwendung von Python und Javascript. Das Konzept ist ganz einfach: In Discord kann jemand eine Nachricht schreiben, diese wird dann von dem Bot überprüft, ob sie einem Muster entspricht (z. B. \"!server start\") und je nach Inhalt leitet der Bot dann die weiteren Schritte ein. Dieser Bot ist zweiteilig, während sich der eine Teil um die Aktualisierung einer Nachricht mit dem online/offline Status des Minecraft-Servers (Minecraft-Plug-in) beschäftig, hört der andere auf die gerade eben beschriebenen Muster und übermittelt auf Befehl an einen NodeJS Server auf meinem Computer den Befehl den Server hochzufahren."},
        {btn: true, JavaScript: true, HTML: true, CSS: true, Typescript: true, BildSource: WunschlistenLogo, buttonfn: () => {handleButtonClick()}, Header: "Weihnachts-Wunschliste", Beschreibung: "Für Weihnachten 2021 habe ich mir etwas besonderes überlegt. Die Wunschliste sollte Digital werden und von überall zugreifbar sein. Dieses Ziel habe ich auf dieser Website umgesetzt! Jeder aus meiner Familie hat einen eigenen Teil, in welchem er seine Wünsche, für die anderen sichtbar, einstellen kann. Diese können mit direkten Links zu dem Artikel, einem Rating und einer benutzerdefinierten Vorschau ausgestattet werden. Klicke auf mehr Erfahren für mehr Infos und eine Demo."},
        {btn: false, Python:true, BildSource: YouTubeDownloadLogo,buttonfn: () => {handleButtonClick()}, Header: "YouTube-Downlaoder", Beschreibung: "Da YouTube aktuell immer weiter Dienste vom herunterladen von Videos sperrt, habe ich mich entschlossen meinen eigenen Bot dafür zu schreiben! Dabei kann ich einfach in eine Textdatei meine zu downloadenden Links speichern, der Bot erkennt diese dann und downloaded die Videos in einen Vorgegbenen Ordner. Das hat den Vorteil, dass ich die Links im Vorfeld herraussuchen kann, und der Bot danach seine Arbeit ohne Aufsicht alleine macht, und ich nicht wie bei den meisten kostenlosen Diensten jeden Link einzeln eingeben muss."},
        {btn: false, BildSource: ComingSoonLogo, buttonfn: () => {handleButtonClick()}, Header: "Future Projects", Beschreibung: ""},
    ]);

    return (
        <div className="ProjektElement ProjektMainContainer">
            <div className="ProjektElement ProjektInnverDIV">
                {Projekte.map(i => 
                    <div className="ProjektElement ProjektProjektspalte">
                    <div className="ProjektElement ProjektBildDIV">
                            <img className="ProjektElement ProjektBild" src={i.BildSource} alt="hehe"></img>
                            {i.btn && <a href={i.Header} target="_blank" rel="noreferrer" className="ProjektElement ProjektMehrInfos"><p className="">Mehr erfahren</p></a>}
                        </div>
                        <div className="ProjektElement ProjektRechteSeite">
                            <div className="ProjektElement ProjektUpperSpalteVonRechts">
                                <h1 className="ProjektElement ProjektHeader">{i.Header}</h1>
                                <div className="ProjektElement ProjektUsedProgrammingLanguages">
                                    {i.Python && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/2048px-Python-logo-notext.svg.png"></img></button>}
                                    {i.Csharp && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox" style={{marginTop: "7px"}}><img alt="" src="https://seeklogo.com/images/C/c-sharp-c-logo-02F17714BA-seeklogo.com.png"></img></button>}
                                    {i.Java && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://www.pinclipart.com/picdir/big/170-1700268_java3-logo-java-clipart.png"></img></button>}
                                    {i.HTML && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://cdn.pixabay.com/photo/2017/08/05/11/16/logo-2582748_1280.png"></img></button>}
                                    {i.CSS && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://cdn.pixabay.com/photo/2017/08/05/11/16/logo-2582747_640.png"></img></button>}
                                    {i.JavaScript && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://cdn.iconscout.com/icon/free/png-512/javascript-2752148-2284965.png"></img></button>}
                                    {i.Typescript && <button onClick={i.buttonfn} className="ProjektElement ProjektLanguageBox"><img alt="" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png"></img></button>}
                                </div>
                            </div>
                            <p className="ProjektElement  ProjektBeschreibung">{i.Beschreibung}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
 
export default ProjekteAuflistung;