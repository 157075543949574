import React from "react";
import EigeneSeite from "../components/EigeneSeite";
import SpammerP from "../pictures/SpammerP.png";
import SpammerP2 from "../pictures/SpammerP2.png";

const SpammerSeite:React.FC = ():JSX.Element => {
    const Überschrift = "Spammer";
    const Bild1 = {path: SpammerP, alt:SpammerP};
    const Bild2 = {path: SpammerP2, alt:SpammerP2};

    const Frage1 = "Was ist der Spammer?";
    const Antwort1 = "Der Spammer ist eine Windows Anwendung, die ich als mein erstes großes C# Projekt programmiert habe. Eigentlich war es nur als kleines Zwischenprojekt zum Üben gedacht, doch daraus wurde mehr. Mit ihm kann man Tastatureingaben simulieren und diese dann eine bestimmte Anzahl oft wiederholen lassen. Zudem kann man den Zeitunterschied zwischen den Ausgaben einstellen. On top hat man eine Menge verschiedener Einstellungsmöglichkeiten, mit denen man das Verhalten des Programms beeinflussen kann.";

    const Frage2 = "Wofür braucht man ihn?";
    const Antwort2 = "Diesen einen Zweck hat er gar nicht, gelegentlich kann man ihn zum vollspammen von Chats oder anderen Sachen verwenden. Dadurch, dass er Tastatureingaben vortäuscht, kann er auch von keinem Anticheat erkannt werden.";

    const DownloadLink = "https://github.com/FinnKrause/Spammer/releases/download/download/Spammer.zip";
    const SourceCode = "https://github.com/FinnKrause/Spammer";

    return (
        <EigeneSeite 
            Überschrift={Überschrift} 
            Bilder={[Bild1, Bild2]} 
            Fragen={[{Frage: Frage1, Antwort: Antwort1}, {Frage: Frage2, Antwort: Antwort2}]} 
            SourceCodeLink={SourceCode} Download={DownloadLink}>
        </EigeneSeite>
    );
}

export default SpammerSeite;