import React from "react";
import NavBar from "../components/NavBar";
import ProjekteAuflistung from "../components/ProjekteAuflistung";
import "../css/ProjektSeite.css";

interface Props {

}

const Projekte:React.FC<Props> = (Props):JSX.Element => {
    return (
        <div className="Projektseite">
            <NavBar></NavBar>
            <ProjekteAuflistung></ProjekteAuflistung>
        </div>
    );
}

export default Projekte;