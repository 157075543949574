import React from "react";
import "../css/404.css";


interface Props {
    originalPage?: string;
}

const NotFound:React.FC<Props> = (Props):JSX.Element => {
    return (
        <div className="notFround">
            <h1 className="big">{"Die Seite " + ((Props.originalPage !== undefined) ? ("\"" + Props.originalPage +"\" wurde nicht gefunden") : "wurde nicht gefunden!")}</h1>
            <p className="little"> "Die Seite " + ((Props.originalPage !== undefined) ? ("\"" + Props.originalPage +"\" wurde nicht gefunden") : "wurde nicht gefunden!")</p>
        </div>
    );
}

export default NotFound;