import React from "react";
import EigeneSeite, {BilderVorschrift} from "../components/EigeneSeite";
import NachrichtOffline from "../pictures/Discord-Bots/Nachricht-offline.png";
import NachrichtOnline from "../pictures/Discord-Bots/Nachricht-online.png";
import ServerStart from "../pictures/Discord-Bots/server start.png";


const DiscordBotsSeite:React.FC = ():JSX.Element => {
    const Überschrift = "Discord-Bots";
    
    const Bild1:BilderVorschrift = {path: NachrichtOnline, alt:NachrichtOnline, UnterschriftBild: "Der Discord-Bot kümmert sich darum, uns (den Spielern) immer zu zeigen, ob der Minecraftserver gerade online ist, oder nicht!"};
    const Bild2:BilderVorschrift = {path: NachrichtOffline, alt:NachrichtOffline, UnterschriftBild: "Hier sehen wir die Nachricht, wenn der Server offline ist. Wenn das der Fall ist, kann man danach den Server mit dem Command \"!server start <Servername>\" starten!"};
    const Bild3:BilderVorschrift = {path: ServerStart, alt:ServerStart, UnterschriftBild: "Hier sehen wir, wie der Server gestartet wird. Nach dieser Nachricht öffnet sich dann ein Fenster auf meinem Desktop-PC, das dann die weiteren Schritte einleitet."};

    const Frage1 = "Wie kommunizieren die verschiedenen Programme miteinander?";
    const Antwort1 = "Um die Nachricht von Discord zu bekommen verwende ich in zwei verschiedene Skripte mit der Discord Library für Python. Um zu prüfen, ob der Server läuft, wird dann ein Ping an meinen PC gesendet und auf eine Antwort gewartet. Wenn nichts zurückkommt, ist der Minecraft-Server folglich aus und es wird eine Fehlermeldung ausgegeben. Das heißt aber noch nicht, dass der PC nicht bereit wäre. Wenn die erste Meldung mit einem Fehler beendet wurde, wird dann eine Anfrage an ein Express.js Skript geschickt, was standardmäßig auf dem Computer läuft, wenn er an ist und dort wird auf eine Antwort gewartet. Wenn diese ankommt, bekommt das gleiche Skript den Befehl ein VB-Script auf dem Computer auszuführen, welches dann im Hintergrund den Server startet.";


    const SourceCode = "https://github.com/FinnKrause/Discord-Bots";

    return(
        <EigeneSeite
            Überschrift={Überschrift} 
            Bilder={[Bild1, Bild2, Bild3]} 
            Fragen={[{Frage: Frage1, Antwort: Antwort1}]} 
            SourceCodeLink={SourceCode}>
        </EigeneSeite>
    );
}

export default DiscordBotsSeite;