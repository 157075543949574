import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../css/VmAdminSeite.css";

interface Props {
    checkurl: string,
    starturl: string,
    viewurl: string,
    name: string,
    screen?: string,
}

enum State {
    offline, online, starting
}

const VmAdminSeite:React.FC<Props> = (Props):JSX.Element => {

    const [status, setStatus] = useState<State>(State.offline);

    const checkStatus = ():void => {
        try {
            Axios.get(Props.checkurl).then((response: any) => {
                console.log(Props.name + ": " + response.data)
                if (response.data === "alive") {
                    setStatus(State.online);
                } else if (status !== State.starting) {
                    setStatus(State.offline);
                } else {
                    setStatus(State.starting);
                }
            });
        } catch (e) {setStatus(State.offline)}
        return;
    }

    const StartVM = () => {
        try {
            Axios.get(Props.starturl).then((response: any) => {
                console.log(response.data);
            });
        }
        catch (e:any) {}
    }

    useEffect(checkStatus, [Props.checkurl, status, Props.name])

    return (
        <div className="VMAdminSeite">
            <h1>{Props.name}</h1>
            <div className="VMCenter">
                <img src={Props.screen} alt=""/>
                <progress max="100" value={(status === State.online) ? "100" : ((status === State.starting) ? "50" : "0")}></progress>
            </div>
            <div className="VMSButtons">
                <button className="VMSButton VMSStatusButton" onClick={() => {(status === State.online) ?window.open(Props.viewurl, "_blank")?.focus() : checkStatus()}}>
                    {(status === State.online) ? <div className="VMSCircle green"></div> : ((status === State.starting) ? <div className="VMSCircle yellow"></div> : <div className="VMSCircle red"></div>)}
                    <p>{(status === State.online) ? "online" : (status === State.starting ? "starting" : "offline")}</p>
                </button>
                {status === State.offline && 
                <button className="VMSButton VMSStartButton" onClick={() => {
                    setStatus(State.starting);
                    StartVM();
                }}>
                <p>Start</p>
                </button>}
            </div>
        </div>
    );
}

export default VmAdminSeite;