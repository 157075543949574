import React from "react";
import logo from "../pictures/Spammer.png";
import "../css/Projekt-Teaser.css";

interface Props {
    onClick?: string,
    buttontext?: string,
}

const Projektteaser:React.FC<Props> = (Props):JSX.Element => {
    return (
        <div className="ProjektVorschau">
            <div className="ElementProjektTeaser innerDIV">
                <img className="ElementProjektTeaser Spammer-Bild" src={logo} alt="Spammer-Projekt"></img>
                <div className="ElementProjektTeaser Block-Rechts">
                    <h1 className="ElementProjektTeaser Header">Projekte</h1>
                    <a href={Props.onClick}><button onClick={() => {}} className="ElementProjektTeaser btnMehrAnsehen">{Props.buttontext}</button></a>
                </div>
            </div>
      </div> 
    )
}

export default Projektteaser;