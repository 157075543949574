import React, {useState } from "react";
import Device from "../components/Device";
import Aufklapper from "./Aufklapper";
import ConfirmationPopup from "./ConfirmationPopup";
import { BUNDESLAND, CITY, COUNTRY, ISP, LAT, LON, TIMESZONE, ZIP, CUSTOMERS,SIGNATURE, ADMIN, ALERT, GOOD, TRASH} from "./SVGs";

interface Props {
    i: any
    Original: any
    müll?: any
    deleteEntry: (ip: any) => void;
}

const IPSpalte:React.FC<Props> = (Props):JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [moreInfo, setMoreInfo] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false); //Sets the GUI state
    const data = Props.Original[Props.i]

    const ARROWDOWN = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00ffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="20" x2="12" y2="10" /><line x1="12" y1="20" x2="16" y2="16" /><line x1="12" y1="20" x2="8" y2="16" /><line x1="4" y1="4" x2="20" y2="4" /></svg>
    const ARROWLEFT = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00ffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="10" y1="12" x2="20" y2="12" /><line x1="10" y1="12" x2="14" y2="16" /><line x1="10" y1="12" x2="14" y2="8" /><line x1="4" y1="4" x2="4" y2="20" /></svg>

    const getLastTime = (time: number):string => {
        const res = new Date().getTime()/1000 - +JSON.stringify(Props.Original[Props.i]["lastlogin"])/1000; //!Got the difference in time in secounds
        if (res < 60) return r(res)+"s"
        else if (res < 60*60) return r(res/60)+"m"
        else if (res < 60*60*24) return r(res/60/60)+"h"
        else if (res < 60*60*24*7) return r(res/60/60/24)+"d"
        else if (res < 60*60*24*7*30) return r(res/60/60/24/7)+"w"
        else if (res < 60*60*60*7*30*356) return rr(res/60/60/24/7/30)+"mo"
        else return "too long..."
    }

    const r = (numb: any):number => {
        return Math.round((numb + Number.EPSILON) * 100) / 100
    }

    const rr = (numb: any):number => {
        return Math.round(numb)
    }

    const getSVG = (key: string):JSX.Element => {
        let returnvalue = ISP;

        switch(key.toLowerCase().trim()) {
            case "country":
                returnvalue = COUNTRY; break;
            case "regionname":
                returnvalue = BUNDESLAND; break;
            case "city":
                returnvalue = CITY; break;
            case "zip":
                returnvalue = ZIP; break;
            case "lat":
                returnvalue = LAT; break;
            case "lon":
                returnvalue = LON; break;
            case "timezone":
                returnvalue = TIMESZONE; break;
            case "org":
                returnvalue = CUSTOMERS; break;
            case "as":
                returnvalue = SIGNATURE; break;
            case "loginDeclined":
                returnvalue = ALERT; break;
            case "loginSuccess":
                returnvalue = GOOD; break;
            default:
                returnvalue = ISP;
        }

        return returnvalue;
    }

    return (
        <div className="IP-Karte">
            {isOpen && confirm && <ConfirmationPopup Frage="Willst du das wirklich tun?" onDecline={() => {setConfirm(!confirm)}} onOk={() => {Props.deleteEntry(Props.i); setConfirm(!confirm)}}></ConfirmationPopup>}
            <div className={`top${(confirm) ? " hidden" : ""}`} onClick={() => setOpen(!isOpen)}>
                <div className="IPFirst">
                    <p>{JSON.stringify(Props.i, null,2).replace("\"", "").replace("\"", "")}</p>
                </div>
                <div className="IPSec">
                    {data["admin"] && ADMIN}
                    <p>{getLastTime(data["lastlogin"])}</p>
                </div>
                <div className="IPThir">{(isOpen) ? ARROWDOWN : ARROWLEFT}</div>
            </div>
            {isOpen && <div className={`IPMoreINFO${(confirm) ? " hidden" : ""}`}>
                {data.Geräte.map((i: any, iidx: number) => (
                    <div className="IPGerät">
                        <Device i={i} idx={iidx}></Device>
                    </div>
                ))}
                <div className={`DeviceHeader GeoHeader ${(!data["admin"] && (data["loginDeclined"] < 1 || !data["loginDeclined"])) ? "mBottom" : ""}`} onClick={() => setMoreInfo(!moreInfo)} style={{marginTop: "10px"}}>
                    <p style={{margin: 0}}>Geolocation-Data</p>
                    <Aufklapper aufgeklappt={moreInfo} height={22} widht={22} right={true}></Aufklapper>
                </div>
                {moreInfo && <div className={`IPInfo`}>
                    {Object.keys(data.ipdata).map((i, iiidx) => (
                        <React.Fragment key={iiidx}>
                            {data.ipdata[i] && <div className="IPDataBlock">
                                {getSVG(i)}
                                <p>{data.ipdata[i]}</p>
                            </div>}
                            {!data.ipdata[i] && <React.Fragment></React.Fragment>}
                        </React.Fragment>
                    ))}
                </div>}
                <div className={`AdminNumberBlock mTOP ${(data["loginDeclined"] === 0 || data["loginSuccess"] === 0) ? "mBottom" : ""}`}><div onClick={() => {
                    setConfirm(!confirm);
                }}>{TRASH}</div><p>DELETE THIS ENTRY</p></div>
                {data["loginDeclined"] >= 1 && <div className={`AdminNumberBlock Rot ${(data["loginSuccess"] === 0) ? "mBottom" : ""}`}>
                    {ALERT}
                    <p>{data["loginDeclined"]}</p>
                </div>}   
                {data["admin"] && <div className="AdminNumberBlock mBottom Grün">
                    {GOOD}
                    <p>{data["loginSuccess"]}</p>
                </div>} 
            </div>}
        </div>
    );
}

export default IPSpalte;