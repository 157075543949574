import React from "react";
import EigeneSeite from "../components/EigeneSeite";
import {BilderVorschrift} from "../components/EigeneSeite";
import WunschlistenSeite from "../pictures/Wunschlisten-Seite.png";

const DiscordBotsSeite:React.FC = ():JSX.Element => {
    const Überschrift = "Weihnachts - Wunschliste";
    
    const Bild1:BilderVorschrift = {path: WunschlistenSeite, alt: WunschlistenSeite, UnterschriftBild: ""};

    const Frage1 = "Genutzte Technologien";
    const Antwort1 = "Für das Backend verwende ich einen Node.js Server, der neue Wünsche annimmt und die gespeicherten Wünsche an das Frontend schickt. Für das Frontend verwende ich wie auch auf dieser Website eine Kombination aus Typescript und React. (Dazu gehören natürlich immer HTML und CSS)";

    const SourceCode = "https://github.com/FinnKrause/weihnachtswunschliste";

    return(
        <EigeneSeite
            Überschrift={Überschrift} 
            Bilder={[Bild1]} 
            Fragen={[{Frage: Frage1, Antwort: Antwort1}]} 
            SourceCodeLink={SourceCode}
            Demo={"https://demo.mykrause.org/"}
            >
        </EigeneSeite>
    );
}

export default DiscordBotsSeite;