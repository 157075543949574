import React from "react";
import "../css/Adminpage.css"

interface Props {
    text: string, 
    changeRightSide: (changeState: JSX.Element) => void,
}

const DropDownElements:React.FC<Props> = (Props):JSX.Element => {
    return (
        <button className="adropdownbtn2" onClick={() => {Props.changeRightSide(<>{Props.children}</>);}}>{Props.text}</button>
    );
}

export default DropDownElements;