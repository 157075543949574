import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../css/AdminBegrüßung.css";

const AdminBegrüßung:React.FC = ():JSX.Element => {

    const [APIVIEWS, setAPIVIEWS] = useState(0);

    useEffect(() => {
        Axios.get("https://api.finnkrause.com/getviews").then(response => {
            setAPIVIEWS(response.data.views);
        })
        const grafana = document.getElementById("grafana")! as HTMLIFrameElement;
        grafana.onerror = function(msg, url, line) {

        }
    }, []);

    return <div className="AdminBegrüßung">
        <iframe id="grafana" title="d" src="https://graf.finnkrause.com/d/sF7d-FHZz/raspberry-pi-monitoring?orgId=1&refresh=1m&kiosk" frameBorder="0"></iframe>
        <div className="Begrüßungsdaten">
            <p>Seitenaufrufe von diesem Gerät: {localStorage.getItem("views")}</p>
            <p>Seitenaufrufe insgesamt auf allen Geräten: {APIVIEWS}</p>
        </div>
    </div>
}

export default AdminBegrüßung;