function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;
    
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }
    
    return os;
    }
    function getBrowser() {
    if (
        (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    )
        return "Opera";
    else if (navigator.userAgent.indexOf("Chrome") !== -1) return "Chrome";
    else if (navigator.userAgent.indexOf("Safari") !== -1) return "Safari";
    else if (navigator.userAgent.indexOf("Firefox") !== -1) return "Firefox";
    else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.DOCUMENT_NODE === true
    )
        return "MSIE";
    else return "unknown";
    }
    function getType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
    }

    export {getOS, getBrowser, getType};