import React, {useState} from "react";
import logo from "../pictures/FinnSchiff3.jpg";
import "../css/Vorstellung.css";

interface Props {

}

const Vorstellung:React.FC<Props> = (Props):JSX.Element => {
    const [daten] = useState([
        {property: "Vorname", content: "Finn"},
        {property: "Nachname", content: "Krause"},
        {property: "Alter", content: "16 Jahre"},
        {property: "Wohnort", content: "Erlangen"},
        {property: "Abschluss", content: "Coming in 2y"},
        {property: "Erfahrung", content: "4y"},
    ]);

    return (
        <div className="Vorstellung">
            <div className="Kartenhalter">
                <div className="Karte">
                    <img className="Element FinnBild" alt="Finn Krause" src={logo}></img>
                    <div className="Karte-back">
                        <h1 className="Daten-Überschrift">Daten</h1>
                        <hr></hr>
                        {daten.map(i => 
                            (<div key={i.property} className="Daten">
                                <div className="leftchild">
                                    <p className="daten-ausgeschrieben">{i.property + ":"}</p>
                                </div>
                                <div className="rightchild">
                                    <p className="daten-ausgeschrieben">{i.content}</p>
                                </div>
                            </div>)
                        )}
                    </div>
                </div>
            </div>

            <div className="Element Content">
                <h1 className="Element VollerName">Finn Krause</h1>
                <p className=" Element Beschreibung"> Hallo, ich bin Finn Krause, ich bin 16 Jahre alt und gehe aktuell auf das Marie-Therese-Gymnasium in Erlangen. 
                Meine Freizeit verbringe ich meistens am Computer, wo ich mir selbst das Programmieren beibringe. 
                Dabei lerne ich sowohl die Programmierung von Desktopanwendungen mit C#, als auch die Programmierung von serverseitigen Programmen mit z. B. Node.js oder Python.
                </p>
            </div>
        </div>
    );
}

export default Vorstellung;