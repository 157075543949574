import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Projektteaser from "../components/Projektteaser";
import Welle from "../components/Welle";
import Vorstellung from "../components/Vorstellung"
import Sprachentable from "../components/SprachenTable";

interface Props {

}

const Main: React.FC<Props> = (Props: any): JSX.Element => {
  useEffect(() => {
    const queryString: string = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("Sprachentable")) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
    if (urlParams.get("h")) {
      setTimeout(() => {
        const UserNameField = document.getElementById("input1")! as HTMLInputElement;
        UserNameField.value = urlParams.get("h") + "";
      }, 150)
    }
    if (urlParams.get("p")) {
      setTimeout(() => {
        const PasswordField = document.getElementById("input2")! as HTMLInputElement;
        PasswordField.setAttribute("type", "password");
        PasswordField.value = urlParams.get("p") + "";
      }, 150)
    }
    setViews();
  }, []);

  const setViews = () => {
    const currentviews = localStorage.getItem("views");
    if (!currentviews) localStorage.setItem("views", "1");
    else localStorage.setItem("views", (+currentviews + 1) + "")
  }

  return (
    <div className="MainSeite">
      <div>
        <div className="MainSeiteVorstellung">
          <NavBar></NavBar>
          <Vorstellung></Vorstellung>
          <Welle welle={1}></Welle>
        </div>
        <Projektteaser onClick="Projekte" buttontext="Mehr erfahren"></Projektteaser>
        <div className="Waveholder">
          <Welle welle={2}></Welle>
          <Welle welle={3}></Welle>
        </div>
      </div>
      <Sprachentable></Sprachentable>
    </div>
  );
}

export default Main;
