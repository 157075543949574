import React, {useState} from "react";
import "../css/Sprachentable.css";

import csharp from "../pictures/sharp.png";
import python from "../pictures/python.png";
import java from "../pictures/java.png";
import webdev from "../pictures/webdev2.png";
import reactnative from "../pictures/react-native.png";
import axios from "axios";

interface Props {}

type Sprachen = {sprache: string, isSpecial: boolean, hasPic: boolean, text: string, bildPfad?: string, isCustom?: boolean};

const Sprachentable:React.FC<Props> = (Props):JSX.Element => {


    const [Sprachen, setSprachen] = useState<Array<Sprachen>>([
        {sprache: "csharp", isSpecial:false, hasPic: true,text: "C# habe ich bereits mit 13 angefangen zu lernen, und habe es seitdem in sämtlichen Projekten verwendet wie zum Beispiel dem Spammer.", bildPfad: csharp},
        {sprache: "python",isSpecial:false,hasPic: true, text: "In Python habe ich Miteilweile ein Jahr Erfahrung und habe es in vielen serverseitigen Programmen im Einsatz. Dabei verwende ich Python am meisten für kleine \"lebenserleichternde\" Programme, die einfache Aufgaben automatisieren.", bildPfad: python},
        {sprache: "java",isSpecial:false,hasPic: true, text: "Java setze ich zum Beispiel für Minecraft-Plugins ein, mit denen ich das Spiel in vielen verschiedenen Wegen beeinflussen kann. Mit Java habe ich bisher die längste Erfahrung mit 3 Jahren.", bildPfad: java},
        {sprache: "Webdevelopment",isSpecial:false, hasPic: true, text: "Im Thema Webdevelopment bin ich mit HTML, CSS, JavaScript und Typescript vertraut und verwende diese in all meinen Projekten. Meist nehme ich noch die Library React mit hinzu.", bildPfad: webdev},
        {sprache: "Mobile", isSpecial:false, hasPic: true, text: "Neben der Möglichkeit Apps für Android in Java oder Kotlin zu schreiben, verwende ich für Apps meist React Native, da ich hier meine Kenntnisse von React mit denen von Typescript verbinden kann. On top sind die Apps dann auch Cross-Plattform", bildPfad: reactnative},
        {sprache: "Teste React aus",isSpecial:true, hasPic: false, text: ""},
    ]);

    const checkforPassword = (input1: string, input2: string) => {
        axios.get(`https://api.finnkrause.com/login/${input1}/${input2}`).then((response: any) => {
            localStorage.setItem("token", response.data.token ? response.data.token : "undefined");
            if (response.data.token) 
                window.location.replace("https://finnkrause.com/admin");
        })
    }

    const getReturn = (i: Sprachen):JSX.Element => {
        return (
            <div key={i.text + i.sprache} className="SprachenElement SprachenUnterElement">
            <div className="SprachenElement SprachenUpper">
                {i.hasPic && <img className={"SprachenElement SprachenBild Sprachen" + i.sprache} src={i.bildPfad} alt={i.sprache}></img>}
                <h1 contentEditable={(i.isCustom) ? true : false} className={"SprachenElement Sprachenname " + i.sprache}>{i.sprache}</h1>
            </div>
            <div className="SprachenElement SprachenBottom">
                {!i.isSpecial && <p className="SprachenElement Programmiersprachenbeschreibung">{i.text}</p>}
                {i.isSpecial && <div className="SprachenElement Programmiersprachenbeschreibung">
                    <input id="input1"  onChange={() => {TextBoxChange(1)}} className="Sprachenelement SprachenInput SprachenInput1" placeholder="Header"></input>
                    <input id="input2" onChange={() => {TextBoxChange(2)}} className="Sprachenelement SprachenInput SprachenInput2" placeholder="Beschreibung"></input>
                    <input id="input3" className="Sprachenelement SprachenInput SprachenInput3" placeholder="Bild-Link (optional)"></input>
                    <button id="btnSubmit" className="Sprachenelemtn SubmitButton" onClick={() => {
                        const Input1 = document.getElementById("input1")! as HTMLInputElement;
                        const Input2 = document.getElementById("input2")! as HTMLInputElement;
                        const Input3 = document.getElementById("input3")! as HTMLInputElement;
                        if (Input1.value === "secret" || Input1.value === "admin") {
                            if (Input1.value === "secret") checkforPassword(Input1.value, Input2.value);
                        } else if (Input2.value  || Input1.value) {
                            const temp = [...Sprachen];
                            const hasPic:boolean = (Input3.value !== "");
                            let bildInput = (Input3.value.toLowerCase() === "random") ? "https://unsplash.it/200/100" : Input3.value;
                            const toPushObject: Sprachen = {sprache: Input1.value, isSpecial: false, hasPic: hasPic, text: Input2.value, bildPfad: bildInput, isCustom: true}
                            temp.push(toPushObject);
                            setSprachen(temp);
                        }
                    }}>Neue Karte erstellen!</button>
                </div>}
            </div>
        </div>
        );
    }

    const TextBoxChange = (nummer: number):void => {
        const Input1 = document.getElementById("input1")! as HTMLInputElement;
        const Input2 = document.getElementById("input2")! as HTMLInputElement;
        const Submit = document.getElementById("btnSubmit")! as HTMLButtonElement;
        if (nummer === 1) {
            if (Input1.value === "secret" || Input1.value === "admin") {
                Input1.className += "red"
                Input2.setAttribute("type", "password");
            } else {
                Input1.className = Input1.className.replace("red", "");
                Input2.setAttribute("type", "text");
            }
        }
        else if (nummer === 1 && Input1.value === "admin") {
            Submit.innerText = "Check"
        }
    }

    return (
        <div>
            <h1 className="Knowledge">Knowledge:</h1>
            <div className="SprachenElement Sprachentable">
                <div className="SprachenElement SpracheninnerDIV">
                    {Sprachen.map(i => getReturn(i))}
                </div>
            </div>
        </div>

    );
}

export default Sprachentable;