import React from "react";
import '../css/NavBar.css';

import NavBarElement from "./NavBarElement";

interface Props {
   
}
const NavBar: React.FC<Props> = (Props):JSX.Element => {
    const githuburl:string = "https://github.com/FinnKrause";
    const instaurl:string = "https://instagram.com/f1nn.krse";
    const youtubeurl:string = "https://www.youtube.com/channel/UC_tHXusgevF0BPO9etN9gFQ/videos";
    const cloudurl:string = "https://cl.finnkrause.com";
    
    return (
        <nav className="navbar">
            <div className="element left">
              <NavBarElement type="button" css="button links github" forward={githuburl}></NavBarElement>
              <NavBarElement type="button" css="button links Cloud" forward={cloudurl}></NavBarElement>
            </div>

            <div className="element name">
              <NavBarElement type="text" text="Finn Krause" forward="/"></NavBarElement>
            </div>

            <div className="element right">
              <NavBarElement type="button" css="button rechts insta" forward={instaurl}></NavBarElement>
              <NavBarElement type="button" css="button rechts youtube" forward={youtubeurl}></NavBarElement>
            </div>
        </nav>
    );
}

export default NavBar;