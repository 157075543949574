import axios from 'axios';
import React, {useState, useEffect} from 'react';
import IPSpalte from "../components/IPSpalte";
import {REFRESH} from "../components/SVGs";
import "../css/UserDataPage.css";

interface Props {
}

enum DIRS {
    ESC = "ESC", DESC = "DESC"
}

const UserDataPage:React.FC<Props> = (Props):JSX.Element => {

    const [usrData, setUsrData] = useState<any>();
    const [dir, setdir] = useState<DIRS>((localStorage.getItem("dir") === DIRS.DESC) ? DIRS.DESC : DIRS.ESC);

    const getUserData = () => {
        axios.get(`https://api.finnkrause.com/userData/getData:${localStorage.getItem("token")}`).then((response: any) => {
            setUsrData(response.data)          
        })
    }

    const deleteEntry = (ip: any) => {
        axios.get(`https://api.finnkrause.com/userData/remove/${ip}/${localStorage.getItem("token")}`).then(reponse => {
            const tempData = {...usrData};
            delete tempData[ip];
            setUsrData(tempData);
        })
    }

    useEffect(() => {
        getUserData();
    }, [])

    const SortData = (a1: any, b1:any):number => {
        const a = usrData[a1];
        const b = usrData[b1];
        if ((dir === DIRS.DESC)) {
            if (a["lastlogin"] > b["lastlogin"]) return 1;
            else if (a["lastlogin"] < b["lastlogin"]) return -1;
            else return 0;
        } else {
            if (a["admin"] && b["admin"]) return 0;
        
            else if (!a["admin"] && !b["admin"]) { 
                if (a["lastlogin"] >= b["lastlogin"]) return -1;
                else return 1;
            }
            else if ((a["admin"] && !b["admin"])) return -1;
            else return 1;
        }
    }

    return (
    <div className="UserData">

        <div className="UserDataHeader">
            <div className="UserDataHeaderLeft"> {/*//TODO: CSS in "Ip-Seite.css" */}
                <div className="SettingsForAlgo">
                    <select id="SortSelect" onChange={(e) => {
                        setdir((e.target.options[e.target.selectedIndex].text === "ESC") ? DIRS.ESC : DIRS.DESC);
                        localStorage.setItem("dir", e.target.options[e.target.selectedIndex].text)
                    }}>
                        <option value="0" selected={(localStorage.getItem("dir") === DIRS.ESC) ? true : false}>ESC</option>
                        <option value="1" selected={(localStorage.getItem("dir") === DIRS.DESC) ? true : false}>DESC</option>
                    </select>
                </div>
            </div>
            <h1 className="UserDataHeaderMiddle">User Data</h1>
            <div className="UserDataHeaderRight" onClick={() => {

            }}>{REFRESH}</div>
        </div>
        <hr />
        {usrData === undefined && usrData === null && usrData === {} && <pre>{"Not permitted!"}</pre>}
        {(usrData !== undefined && usrData !== null && usrData !== {}) && (
        <div id="IPHolder">
            {usrData && (Object.keys(usrData).sort(SortData).map((key: any, idx: number) => (
                <IPSpalte key={idx+"AdminPage"} i={key} Original={usrData} müll={dir} deleteEntry={deleteEntry}></IPSpalte>  
            )))}
        </div>)}
    </div>);
}
 
export default UserDataPage;