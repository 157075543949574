import React from "react";

interface Props {
    aufgeklappt: boolean,
    height?: number,
    widht?: number
    right?: boolean,
}

const Aufklapper:React.FC<Props> = (Props):JSX.Element => {
    if (!Props.aufgeklappt) {
        if (Props.right) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={Props.widht ? Props.widht: 44} height={Props.height ? Props.height: 44} viewBox="0 0 24 24" stroke-width="1.5" stroke="#00ffff" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>  <line x1="10" y1="12" x2="20" y2="12" />  <line x1="10" y1="12" x2="14" y2="16" />  <line x1="10" y1="12" x2="14" y2="8" />  <line x1="4" y1="4" x2="4" y2="20" /></svg>
            );
        }
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={Props.widht ? Props.widht: 44} height={Props.height ? Props.height: 44} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="20" y1="12" x2="10" y2="12" /><line x1="20" y1="12" x2="16" y2="16" /><line x1="20" y1="12" x2="16" y2="8" /><line x1="4" y1="4" x2="4" y2="20" /></svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={Props.widht ? Props.widht: 44} height={Props.height ? Props.height: 44} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="20" x2="12" y2="10" /><line x1="12" y1="20" x2="16" y2="16" /><line x1="12" y1="20" x2="8" y2="16" /><line x1="4" y1="4" x2="20" y2="4" /></svg>
        );
    }
}

export default Aufklapper;