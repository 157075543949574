import React from "react";
import EigeneSeite from "../components/EigeneSeite";
import BegrüßungBild from "../pictures/MC-Plugin/StartScreen.png";
import homeCommand from "../pictures/MC-Plugin/home.png";
import Posi1 from "../pictures/MC-Plugin/Posi1.png";
import Posi2 from "../pictures/MC-Plugin/Posi2.png";
import Posi3 from "../pictures/MC-Plugin/Posi3.png";
import Villager1 from "../pictures/MC-Plugin/villager_neueFrage.png";
import Villager2 from "../pictures/MC-Plugin/villager_fragebeantworten.png";
import Villager3 from "../pictures/MC-Plugin/villager_fragerichtig.png";
import Villager4 from "../pictures/MC-Plugin/villager_bekommen.png";
import Villager5 from "../pictures/MC-Plugin/villager_inventar.png";
import Message1 from "../pictures/MC-Plugin/message1.png";
import Message2 from "../pictures/MC-Plugin/message2.png";


const MCPluginSeite:React.FC = ():JSX.Element => {
    const Überschrift = "MC-Plugin";

    const Bild1 = {path: BegrüßungBild, alt:BegrüßungBild, ÜberschriftBild:"Startscreen", UnterschriftBild:"Wenn man dem Server betritt, bekommt man zunächst einmal eine Liste der aktuellen Commands zugeschickt."};
    const Bild2 = {path: homeCommand, alt: homeCommand, ÜberschriftBild:"Home-Command", UnterschriftBild:"Wenn man sich auf einem geclaimten Chunk, im End oder im Nether befindet, kann man sich mit diesem Command \"/home\" einfach zu dem zuletzt gesetzten Bett teleportieren."};
    const Bild3 = {path: Posi1, alt: Posi1, ÜberschriftBild: "Posi-Comand", UnterschriftBild: "Den Posi Command gibt es in 3 Ausführungen! Mit dem Befehl \"/posi\" kann man sich einfach und schnell seine eigene Position ausgeben lassen."};
    const Bild4 = {path: Posi2, alt: Posi2, ÜberschriftBild: "", UnterschriftBild: "Hier sehen wir die 2. Möglichkeit, mit der man sich die Position von einem anderen Mitspieler ausgeben lassen kann."};
    const Bild5 = {path: Posi3, alt: Posi3, ÜberschriftBild: "", UnterschriftBild: "Und zuletzt die 3. Form: Hiermit kann man die Position von einem beliebigen Spieler an einen anderen Spieler schicken."};
    const Bild6 = {start: true, path: Villager1, alt: Villager1, ÜberschriftBild: "XP-Villager", UnterschriftBild: "Um den XP-Villager bekommen zu können, muss man zuerst 10 Fragen (Allgemeinwissen) in Folge richtig beantworten! Fragen kann man in zufällig generierten Abständen mit Rechtsklick auf das Drachen-Ei bekommen! Wenn eine neue Fragen zur Verfügung steht, bekommt man wie auf dem Bild zu sehen eine Nachricht."};
    const Bild7 = {path: Villager2, alt: Villager2, ÜberschriftBild: "", UnterschriftBild: "Nach dem Click auf das Drachen-Ei wird einem die Frage gestellt, die man dann mit dem \"/answer\" Command beantworten kann."};
    const Bild8 = {path: Villager3, alt: Villager3, ÜberschriftBild: "", UnterschriftBild: "Wenn man die Frage dann richtig beantwortet hat, bekommt man die Meldung, dass alles richtig war und den eigenen Status, wie viele man jetzt in Folge richtig hatte."};
    const Bild9 = {path: Villager4, alt: Villager4, ÜberschriftBild: "", UnterschriftBild: "Wenn man es dann geschafft hat 10 Fragen in Folge richtig zu beantworten, bekommt man die Nachricht, dass man sich jetzt den eigenen Villager spawnen kann."};
    const Bild10 = {path: Villager5, alt: Villager5, ÜberschriftBild: "", UnterschriftBild: "Wenn man diesen Gespawnt hat, dann kann nur man selbst mit diesem Interagieren und nur man selbst kann diesen töten und somit verschieben, denn sonst bleibt er immer an Ort und Stelle."};
    const Bild11 = {path: Message1, alt: Message1, ÜberschriftBild: "Message", UnterschriftBild: ""};
    const Bild12 = {path: Message2, alt: Message2, ÜberschriftBild: "", UnterschriftBild: "Mit dem \"/message\" Command kann man anderen Spielern, die nicht gerade auf dem Server sind eine Nachricht schicken, die ihnen dann beim nächsten mal beitreten angezeigt wird."};

    const DownloadLink = "https://github.com/FinnKrause/Minecraft-Plugin/releases/download/DownloadJAR/FinnsPluginII.jar";
    const SourceCode = "https://github.com/FinnKrause/Minecraft-Plugin";

    return (
        <EigeneSeite 
            Überschrift={Überschrift} 
            Bilder={[Bild1, Bild2, Bild3, Bild4, Bild5, Bild6, Bild7, Bild8, Bild9, Bild10, Bild11, Bild12]} 
            Fragen={[]} 
            Download={DownloadLink} SourceCodeLink={SourceCode}>
        </EigeneSeite>
    );
}

export default MCPluginSeite;