import React from "react";

interface Props {
    type: "button" | "text";
    text?: string;
    forward?: string;
    css?: string;
}

const NavBarElement: React.FC<Props> = (Props):JSX.Element => {
    
    const getReturn = ():JSX.Element => {
        if (Props.type === "text") {
            return (
                <a href={Props.forward} style={{textDecoration: "none", margin: 0, padding: 0}}>
                    <h1 className={Props.css}>{Props.text}</h1>
                </a>    
            );
        }
        else {
            return (
                <a href={Props.forward} target="_blank" rel="noreferrer">
                    <button className={Props.css}></button>
                </a>    
            );
        }
    }
    
    return getReturn();
}

export default NavBarElement;