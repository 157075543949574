import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import React, {useEffect} from "react";
import Axios from "axios";
import {getBrowser, getOS, getType} from "./components/GetData";

import Main from "./pages/Main"
import NotFound from "./pages/404"
import Projektseite from "./pages/Projekte";

import SpammerSeite from "./pages/SpammerSeite";
import MCPluginSeite from "./pages/MCPluginSeite";
import DiscordBotsSeite from "./pages/DiscordBotsSeite";
import Wunschliste from "./pages/Wunschliste";

import Adminpage from "./pages/Adminpage";
import "./css/Grundsachen.css";

const App:React.FC = ():JSX.Element => {


    useEffect(() => {
        Axios.get("https://api.finnkrause.com/setviews");
        Axios.get(`https://api.finnkrause.com/userData/register/${getOS()}/${getBrowser()}/${getType()}`);
    }, [])

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Main}></Route>
                <Route exact path="/defaultsite" component={Main}>
                    <Redirect to="/"></Redirect>
                </Route>
                <Route exact path="/Projekte" component={Projektseite}></Route>
                <Route exact path="/Spammer" component={SpammerSeite}></Route>
                <Route exact path="/Minecraft-Plugin" component={MCPluginSeite}></Route>
                <Route exact path="/Discord-Bots" component={DiscordBotsSeite}></Route>
                <Route exact path="/admin" component={Adminpage}></Route>
                <Route exact path="/Weihnachts-Wunschliste" component={Wunschliste}></Route>
                <Route exact path="/YouTube-Downlaoder" component={Main}>
                    <Redirect to="/"></Redirect>
                </Route>
                <Route exact path="/404" component={NotFound}></Route>
                <Redirect to="/"/>
            </Switch>
        </Router>
    );
}

export default App;