import React, { useEffect, useState } from "react";
import AdminButton from "../components/AdminButton";
import DropDownElements from "../components/DropDownElements";
import VmAdminSeite from "../components/VmAdminSeite";
import AdminBegrüßung from "../components/AdminBegrüßung";
import UserDataPage from "../components/UserDataPage";
import Main from "./Main";
import "../css/Ip-Seite.css";
import "../css/Adminpage.css";
import "../css/Device.css";
import WindowsScreen from "../pictures/windows10_screen.png";
import KaliScreen from "../pictures/kali_screen.png";
// import RaspbianScreen from "../pictures/raspbian_screen.png";
import Windows11Screen from "../pictures/windows11.jpg";
import axios from "axios";
import {DNSSVG, GUMCPSVG, CODESVG, DOCKERSVG, GRAFANASVG, DATASVG, LOGOUTSVG} from "../components/SVGs";

const Adminpage:React.FC = ():JSX.Element => {

    const [nav, setnav] =                       useState<boolean>((localStorage.getItem("navbaropen") === "true"));
    const [isTokenCorrect, setTokenCorrect] =   useState<boolean>();
    const [rightside, setrightside] =           useState<JSX.Element>(<AdminBegrüßung></AdminBegrüßung>);

    const redirectToPage = (website: string) => {
        window.open(website, "_blank");
    }

    const setNavBarOpened = (state: boolean):void => {
        localStorage.setItem("navbaropen", state + "");
        setnav(!nav);
        return;
    }

    useEffect(() => {
        if (isTokenCorrect) return;
        const storedtoken = localStorage.getItem("token")?.toString();
        if (storedtoken === undefined || storedtoken === null || storedtoken === "" ) {setTokenCorrect(false)}
        axios.get(`https://api.finnkrause.com/validate:${storedtoken}`).then((response: any) => {
            if(response.data === true) { 
                setTokenCorrect(true);
            }
            else {
                setTokenCorrect(false); //!false
            }
        })
    }, [isTokenCorrect])
    
    if (isTokenCorrect) {
        return (
            <div className="Adminpage">
                <div className={(nav ? "adminnav alinks" : "adminnav alinksklein")}>
                    {!nav &&  <button onClick={() => {setNavBarOpened(!nav);}}><svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" stroke="#00abfb" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="4" y1="6" x2="20" y2="6" /><line x1="4" y1="12" x2="20" y2="12" /><line x1="4" y1="18" x2="20" y2="18" /></svg></button>}
                    {nav && <div className="AdminNavBar">
                    <div className="TopNavBar">
                            <h1 onClick={() => {setNavBarOpened(!nav)}} onDoubleClick={() => {setrightside(<AdminBegrüßung></AdminBegrüßung>)}}>Admin Page</h1>
                            <ul>
                            <AdminButton displayname="VMs" aufklappbar={true}>
                                    <DropDownElements text="Windows 10" changeRightSide={setrightside}>
                                        <VmAdminSeite key="1" name="Windows 10" 
                                            checkurl="https://check.finnkrause.com/mainvm" 
                                            starturl="https://start.finnkrause.com/mainvm" 
                                            viewurl="https://vm.finnkrause.com/?host=vm.finnkrause.com/mainvm&port=80&path=tightvnc"
                                            screen={WindowsScreen}>
                                        </VmAdminSeite>
                                    </DropDownElements>

                                    <DropDownElements text="Windows 11" changeRightSide={setrightside}>
                                        <VmAdminSeite key="2" name="Windows 11" 
                                            checkurl="https://check.finnkrause.com/win11vm" 
                                            starturl="https://start.finnkrause.com/win11vm" 
                                            viewurl="https://vm.finnkrause.com/?host=vm.finnkrause.com/win11vm&path=tightvnc"
                                            screen={Windows11Screen}>
                                        </VmAdminSeite>
                                    </DropDownElements>

                                    <DropDownElements text="Kali Linux" changeRightSide={setrightside}>
                                        <VmAdminSeite key="3" name="Kali Linux" 
                                            viewurl="https://vm.finnkrause.com/?host=vm.finnkrause.com/kalivm8&port=80" 
                                            checkurl="https://check.finnkrause.com/kalivm" 
                                            starturl="https://start.finnkrause.com/kalivm" 
                                            screen={KaliScreen}></VmAdminSeite>
                                    </DropDownElements>
                                    {/* 
                                    <DropDownElements text="Raspbian Linux" changeRightSide={setrightside}>
                                        <VmAdminSeite key="4" name="Raspbian" 
                                            viewurl="https://vm.finnkrause.com/?host=vm.finnkrause.com/raspbianvm"
                                            checkurl="https://check.finnkrause.com/raspbianvm"
                                            starturl="https://start.finnkrause.com/raspbianvm"
                                            screen={RaspbianScreen}>
                                        </VmAdminSeite>
                                    </DropDownElements> */}
                                </AdminButton>
                                <AdminButton displayname="DNS Server" aufklappbar={true} customsvg={DNSSVG}>
                                    <DropDownElements text="192.168.178.62" changeRightSide={() => {redirectToPage("https://dns16862.finnkrause.com")}}></DropDownElements>
                                    <DropDownElements text="192.168.178.47" changeRightSide={() => {redirectToPage("https://dns16847.finnkrause.com")}}>
                                    <h1>Henni</h1>
                                    </DropDownElements>
                                </AdminButton>
                                <AdminButton displayname="Grafana" aufklappbar={true} changeRightSide={() => {redirectToPage("https://graf.finnkrause.com")}} customsvg={GRAFANASVG}>
                                    <DropDownElements text="Grafana" changeRightSide={() => {redirectToPage("https://graf.finnkrause.com/")}}></DropDownElements>
                                    <DropDownElements text="Dashborad" changeRightSide={() =>  {
                                        redirectToPage("https://graf.finnkrause.com/d/sF7d-FHZz/raspberry-pi-monitoring?orgId=1&refresh=5s&kiosk=tv");
                                    }}></DropDownElements>
                                </AdminButton>
                                
                                <AdminButton displayname="VSCode" aufklappbar={false} customsvg={CODESVG} changeRightSide={() => {redirectToPage("https://vscode.finnkrause.com")}}/>
                                <AdminButton displayname="DB / PHP" aufklappbar={false} customsvg={GUMCPSVG} changeRightSide={() => {redirectToPage("https://stats.finnkrause.com")}}/>
                                <AdminButton displayname="Docker" aufklappbar={false} changeRightSide={() => {redirectToPage("https://docker.finnkrause.com")}} customsvg={DOCKERSVG}/>
                                <AdminButton displayname="UserData" aufklappbar={false} changeRightSide={() => {
                                    setrightside(<UserDataPage/>)
                                }} customsvg={DATASVG}/>
                            </ul>
                        </div>
                        <div className="logout">
                            <AdminButton displayname="logout" aufklappbar={false} changeRightSide={() => {
                                localStorage.setItem("token", "undefined");
                                window.location.replace("https://finnkrause.com/")
                            }} customsvg={LOGOUTSVG}>
                            </AdminButton>
                        </div>
                    </div>}
                </div>
                <div className="arechts">
                    {rightside}
                </div>
            </div>

        );
    }
    else return <Main></Main>;
}

export default Adminpage;