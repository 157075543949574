import React, { useState } from "react";
import Aufklapper from "../components/Aufklapper";

interface Props {
    displayname: string, 
    aufklappbar: boolean,
    changeRightSide?: (changeState: JSX.Element) => void
    customsvg?: JSX.Element
}

const AdminButton:React.FC<Props> = (Props):JSX.Element => {

    const [state, setstate] = useState(false);
    const defaultSVG:JSX.Element = <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="4" width="18" height="12" rx="1" /><line x1="7" y1="20" x2="17" y2="20" /> <line x1="9" y1="16" x2="9" y2="20" /> <line x1="15" y1="16" x2="15" y2="20" /></svg>;
    
    return (
        <li>
            <div className="AdminButton">
                <div  id="agroßpunkt" className="anavbutton">
                    {Props.customsvg && Props.customsvg}
                    {!Props.customsvg && defaultSVG}

                    {Props.aufklappbar && <button onClick={() => {setstate(!state)}}>{Props.displayname}</button>}
                    {!Props.aufklappbar && <button onClick={() => {Props.changeRightSide && Props.changeRightSide(<div>{Props.children}</div>);}}>{Props.displayname}</button>}
                    
                    {Props.aufklappbar && <Aufklapper aufgeklappt={(state) ? true: false}></Aufklapper>}
                </div>
                {Props.aufklappbar && state && <div className="adropdown">
                    {Props.children}
                </div>}
            </div>
        </li>
    );
}

export default AdminButton;